// Fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

// Variables
@import 'variables';

// Custom Bootstrap Color
$theme-colors: (
  primary: #FF8F0C,
  secondary: #727272,
  info: #1593FF,
);

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//以下、オリジナルスタイル
$container_w: 540px; //フォームの最大幅

//フォントサイズ
$base_fontsize: 12px;
$small_fontsize: 10px;
$btn_fontsize: 15px;

//イメージ
$img_bg: url(/image/bg.png); //背景画像
$img_star: url(/image/star.png); //サインアップ画面の装飾画像
$img_ft:  url(/image/ft-present.png);　//フッターの背景画像

//全画面共通
html,
body{
    height: 100%;
}
html{
    font-size: $base_fontsize;
    img{
        width: 100%;
        height: auto;
    }
    a:hover{
        text-decoration-line: none;
    }
    body,
    .v-application{
        font-family: 'roboto', 'Noto Sans JP', sans-serif!important;
    }
    .v-application--wrap{
        min-height: 100%!important;
    }
    .v-tabs-items .v-list-item,
    .award-list-item{
        .v-list-item__title{
            font-family: Noto Sans JP;
            font-weight: bold;
            color: #0F6570;
        }
        .v-list-item__subtitle{
            color: #222222;
        }
    }
    .v-list-item--active .v-icon.mdi-account{
        color : #fff!important;
    }
}
#app{
    height: 100%;
    .v-main{
        z-index: 2;
    }
    position: relative;
    // max-width
    .max-540{
        max-width: 540px;
    }
    .content-wrap{
        position: fixed;
        top: 56px;
        left: 0;
        right: 0;
        width: 100%;
        height: calc(100% - 112px);
    }
    .scroll-wrap{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        overflow: scroll;
        scroll-behavior: smooth;
    }
    //背景の設定
    background-image: $img_bg;
    background-repeat: repeat;
    background-size: 200px;
    z-index: 0;
    width: 100%;
    //select要素のcssバグの調整
    fieldset{
        border-width: 2px!important;
        top : 0px!important;
        color: #cccccc;
    }
    .error--text{
        font-weight: bold;
        fieldset{
            border-color: #ff5252;
        }
    }
    // labelの位置をカスタマイズ
    .v-label{
        color: #222;
        font-weight: bold;
        font-size: 0.9em!important;
        &--active{
            transform: none!important;
        }
    }
    // ギフトをおくるページのヘッダー調整
    .v-toolbar__content{
        padding-top: 0;
        padding-bottom: 0;
    }
    // おくりたい人とメッセージのみ位置を調整
    .v-input{
        &.loading{
            .v-input__icon--clear{
                    display: none!important;
            }
        }
        &:not(.radio-group){
            .v-label{
                top: -2em!important;
                left: -12px!important;
            }
            // labelの背景を白にするlegendを非表示
            legend{
                display: none!important;
            }
            // error-messageの位置をinput左に行揃え
            .v-messages{
                left: -12px!important;
            }
        }
        &.radio-group{
            .v-label{
                margin-bottom: 0;
            }
            .v-input--radio-group__input{
                justify-content: space-evenly;
            }
        }
        // メッセージ
        &.v-textarea{
            .v-input__append-inner{
                margin-top: 11px!important;
            }
            textarea{
                resize: none!important;
            }
        }
        .v-icon--link .mdi-close{
            color: inherit;
        }
    }
}
//サインイン・サインアップ共通
#sign-in,
#sign-up,
#verification,
#reset-password{
    input{
        font-size : 16px;
    }
    & > div{
        height: 100%;
    }
    nav + div{
        margin-top: 55px;
    }
    nav{
        position: fixed;
        top: 0;
        height: 50px;
        width: 100%;
        display : flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.2);
        z-index: 5;
        h1{
            color: #222222;
            font-size: 16px;
            margin: 0;
        }
    }
    .scroll-wrap{
        height: calc(100% - 55px);
        margin-top: 55px;
        overflow: scroll;
    }
    //装飾の配置を調整
    .img-star{
        width: 100%;
        min-width: 320px;
        max-width: 540px;
        height: 1px;
        position: relative;
        margin: 0 auto;
        z-index: 1;
        &::before{
            content: "";
            display: inline-block;
            width: 61px;
            height: 89px;
            position: absolute;
            right: 0px;
            top: 25px;
            background: $img_star center/contain;
            z-index: 1;
        }
    }
    //フォームの横幅を設定
    .container{
        max-width: $container_w;
    }
    .margin-for-scroll{
        margin-bottom: 100px;
        z-index: 2;
    }
    //ボタンのレイアウト
    .btn{
        min-width: 180px;
        min-height: 42px;
        font-size: $btn_fontsize;
        border-radius: 21px;
        position: relative;
        color: #fff;
        border: none;
        > span.text{
            margin: 0 20px 0 20px;
            padding-right: 5px;
            display: inline-block;
        }
        > span.icon {
            position: absolute;
            display: inline-block;
            top: 50%;
            margin-top: -7px;
            right: 13px;
        }
        &:focus{
            outline: 0;
        }
        &-back{
            > span.icon {
                left: 13px;
            }
        }
        .icon{
            height: 14px;
            width: 14px;
            background-color: #fff;
            border-radius: 50%;
            &::before{
                content: '';
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                width: 4px;
                height: 4px;
                border: 0.1em solid currentColor;
                border-left: 0;
                border-bottom: 0;
                box-sizing: border-box;
                position: absolute;
                top: 4px;
                left: 4px;
            }
            &.next{
                &::before{
                    content: '';
                    color: #e57b00;
                    transform: translateY(25%) rotate(45deg);
                    left: 4px;
                }
            }
            &.back{
                &::before{
                    content: '';
                    color: #727272;
                    transform: translateY(25%) rotate(-135deg);
                    left: 6px;
                }
            }
        }
    }
    //入力フォーム調整
    label{
        font-size: 11px;
    }
    input{
        &:not(.form-check-input){
            height: 40px;
        }
        padding-bottom: 8px;
        padding-top: 8px;
    }
    strong{
        font-size: 11px;
    }
    ::placeholder{
        color: #C3C3C3;
        font-size: 15px;
    }
    :-ms-input-placeholder{
        color: #C3C3C3;
        font-size: 15px;
    }
    ::-ms-input-placeholder{
        color: #C3C3C3;
        font-size: 15px;
    }
    //フッターの位置と画像を調整
    .ft-bg{
        position: fixed;
        bottom: 0;
        z-index: 3;
        &::before{
            content: "";
            position: fixed;
            width: 100%;
            height: 60px;
            display: block;
            background: url("/image/ft-present.png") bottom/cover repeat-x;
            bottom: 0;
            z-index: 3;
        }
    }
    .ft-santa::before{
        content: '';
        position: fixed;
        bottom: -2px;
        left: -20px;
        width: 110px;
        height: 86px;
        background: url("/image/ft-santa.png") bottom/contain no-repeat;;
        z-index: 4;
    }
    .ft-present::after{
        content: '';
        position: fixed;
        bottom: -2px;
        right: 55px;
        width: 80px;
        height: 64px;
        background: url("/image/ft-bag.png") bottom/contain no-repeat;
        z-index: 4;
    }
    .ft-parts{
        max-width: 540px;
        width: 100%;
        height: 0;
        margin: 0 auto;
        position: relative;
    }
    .footer{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 100px;
        text-align: center;
        overflow: hidden;
        z-index: 5;
    }
}

//サインインのみ
#sign-in{
    .image-wrap{
        margin: 0 auto;
        max-width: 350px;
    }
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
        background-color:#FF8F0C;
    }
    .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
    .custom-control-input.is-valid:checked ~ .custom-control-label::before{
        background-color: #5cd08d;
    }
    p{
        font-size: 15px;
    }
    a{
        text-decoration: none!important;
    }
    .text-link .icon{
        height: 14px;
        width: 14px;
        background-color: #FF8F0C;
        border-radius: 50%;
        margin-right: 5px;
        position: relative;
        &::before{
            content: '';
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            width: 4px;
            height: 4px;
            border: 0.1em solid currentColor;
            border-left: 0;
            border-bottom: 0;
            box-sizing: border-box;
            position: absolute;
            top: 4px;
            left: 4px;
        }
        &.next{
            &::before{
                content: '';
                color: #fff;
                transform: translateY(25%) rotate(45deg);
                left: 4px;
            }
        }
    }
}

//サインアップのみ
#sign-up{
    overflow: hidden;
    nav{
        z-index: 5;
    }
    a.underline{
        text-decoration: underline!important;
    }
    //エラーメッセージが表示される場合にヒントを非表示にする
    .was-validated{
        input:invalid{
           + .text-muted{
                display: none;
            }
        }
    }
    input.is-invalid{
        + .text-muted{
            display: none;
        }
    }
}

#reset-password{
    overflow: hidden;
}
#verification{
    // .container{
    //     margin-top: 100px;
    // }
    h2{
        color: #0F6570;
        padding: 0 2rem;
    }
    p{
        padding: 0 2rem;
    }
}

@media screen and (max-width:767px) {
    /*　画面サイズが767px以下の場合読み込む　*/
    #app{
        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none;
    }
}
@media screen and (min-width:768px){
    /*　画面サイズが768px以上の場合読み込む　*/
    .ft-bg{
        &::before{
            background-size: contain!important;
        }
    }
}
